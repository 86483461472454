<template>
  <div class="companies-users">
    <router-view @onSelect="$emit('onSelect', $event)" />
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style lang="scss" scoped>
.companies-users {
}
</style>
